/* Footer Styles */
/* tailwind.css */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'Sephir'; 
  src: url('../fonts/Sephir-Regular.otf') format('opentype');
}

.footer {
  color: #ffff;
    background-color: #000114;
    padding: 0; /* Remove padding */
    text-align: center;  /* Center content horizontally */
}

.footer-content {
    display: flex;
    justify-content: space-between;  /* Distribute content evenly */
    align-items: center;  
    flex-wrap: wrap;  /* Allow content to wrap on smaller screens */
    object-fit: cover;
}
.footericon {
  text-align: center; /* Center the content horizontally */
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */
  object-fit: cover;
}

.footerimage {
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Maintain aspect ratio */
  margin: 0 10px; /* Adjust spacing between images */
  margin-bottom: 10px;
}

.footerinsta {
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Maintain aspect ratio */
  margin-bottom: 10px;
}

.iconheading {
  font-family: 'taviraj-regular';
  font-size: 14px;
  padding-top: 20px;
}


.brand {
  display: contents;
}
.brandname {
  color: #FFD700;
  font-family: 'Sephir';
  margin-top: 30px;  
}
.brandaddress {
  font-family: 'taviraj-regular';
}
.contact {
  font-family: 'taviraj-regular';
}

.contact{
  margin-top: 50px;

}


