/* Footer Styles */
/* tailwind.css */

@font-face {
  font-family: 'Sephir'; 
  src: url('../fonts/Sephir-Regular.otf') format('opentype');
}

.footer {
  color: #ffff;
    background-color: #1f3828;
    padding: 0; /* Remove padding */
    text-align: center;  /* Center content horizontally */
}

.footer-content {
    display: flex;
    justify-content: space-between;  /* Distribute content evenly */
    align-items: center;  /* Align content vertically */
    flex-wrap: wrap;  /* Allow content to wrap on smaller screens */
    object-fit: cover;
}


.footer-image {

  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Maintain aspect ratio */
  margin: 0 10px; /* Adjust spacing between images */
  margin-bottom: 10px;
}
.footer-insta {
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Maintain aspect ratio */
  margin-bottom: 10px;
}
.icon-heading {
  text-align: center;  
  font-family: 'taviraj-regular';  
  font-size: 14px; 
  padding-top: 20px;
}


.brand {
  display: contents;
}
.brand-name {
  color: #FFD700;
  font-family: 'Sephir';
  font-size: 20px;
  margin-top: 30px;
}
.brand-address {
  font-family: 'taviraj-regular';
}
.contact {
  font-family: 'taviraj-regular';
  color: white;
}

.contact{
  margin-top: 50px;

}


.icons {
  display: flex;
  align-items: center;
  justify-content: center; 
  flex-wrap: wrap; 
  object-fit: cover;
}

