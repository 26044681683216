@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 0 80px 80px 80px; /* Added padding around the container */
  background-color: #f8f8f8;
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  
}

.card {
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  margin-top: 30px; /* Added margin-top to create space between rows */
   
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.card img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.card-info {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 8px 12px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.cardname {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 10px;

  
}

.cardrow {
  font-family: sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 30px;
}
/* Rest of the styles remain the same */
