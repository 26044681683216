.navbar {
  background-color: #ffffff;
  height: 60px;
  display: flex;
  align-items: center;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoStyle {
  max-height: 60px; 
  width: 100px;
  max-width: 300px; 
  object-fit: cover;
}