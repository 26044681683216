

.navbar {
    background-color: #ffffff;
    color: #000000;
    padding: 5px;
    display: flex;
    justify-content: center; 
    font-family: 'Sephir';
    font-size: xx-large;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
  }


  .navbar a {
    color: #fff;
    text-decoration: none; /* Remove underline */
    padding: 10px;
  }
  
  .navbar a:hover {
    background-color: #ddd; /* Change background on hover */
    color: #333;
  }
